* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-bosx;
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

#root {
    height: 100%;
}
