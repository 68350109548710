.container {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #121212;
    height: 60px;
    color: white;
}

.linksContainer {
    display: flex;
    align-items: center;
}

.linksContainer > a {
    text-decoration: none;
    color: white;
    font-size: 0.95em;
}

.linksContainer > a:first-child {
    margin-right: 20px;
}

span {
    margin-left: auto;
    opacity: 0.4;
    font-size: 0.8em;
}

/* Xs */
@media only screen and (max-width: 600px) {
    .container {
        height: 70px;
        padding-top: 4px;
        flex-direction: column;
        justify-content: center;
    }

    .linksContainer {
        margin-bottom: 6px;
    }

    .linksContainer > a {
        font-size: 0.8em;
    }

    span {
        margin: 0 auto;
    }

}
