.container {
    min-height: 100%;
    background: white;
}

.mainContainer {
    margin: 0px auto 45px auto;
    padding: 45px 15px 0 15px;
    min-width: 250px;
    max-width: 400px;
    min-height: 100vh;
}

.logoWrapper {
    width: 100%;
    height: 200px;
}

.logoContainer {
    margin: 0 auto;
    height: 200px;
    width: 200px;
}

.socialMediaWrapper {
    height: 50px;
    width: 50px;
}

.socialMediaContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    height: 50px;
}

.loadMoreContainer {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.centerContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
